import React from "react";
import SalePrice from "../../modules/Home/images/sale-price.svg";
import { TitleHomeWithIcon } from "../../modules/Home/components/TitleHomeWithIcon";
import { colors } from "../../styles/colors";

const SuccessComponent = ({
  handleClick,
  isMobile,
  isRenewal,
  priceQuote,
  brand,
  model,
  version,
  year,
  kms,
}) => {

  return (
    <div
      style={{
        height: isMobile ? "" : "420px",
      }}
      className="d-flex align-items-center justify-content-between flex-column"
    >
      {isMobile ? (
        <TitleHomeWithIcon
          title={"Tu cotización fue\n realizada con éxito."}
          fontSize={"28px"}
          colorText={colors.purple.purple}
          colorIcon={colors.purple.purple}
        />
      ) : null}
      <div className="d-flex flex-column align-items-center justify-content-center container-card-cotizacion  mt-3">
        <div
          className="d-flex align-items-center justify-content-start"
          style={{ width: "100%" }}
        >
          <img
            src={SalePrice}
            alt="auta-button"
            className="card-icon-cotizacion"
          />
          <div className="d-flex align-items-start justify-content-center flex-column ms-2 ms-md-3">
            <p className="car-model-cotizacion">
              {brand} {model}
            </p>
            <p className="car-version-cotizacion">{version}</p>
            <p className="car-year-kms-cotizacion mt-2">
              {year} | {kms} kms
            </p>
          </div>
        </div>
        <div
          className="container-cotizacion-price d-flex flex-column align-items-center justify-content-center mt-3"
          style={{ width: isMobile ? "100%" : "" }}
        >
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ width: "100%" }}
          >
            <p className="text-offer-initial me-md-5">Oferta inicial</p>
            <div className="d-flex align-items-center justify-content-center ms-md-5 d-none d-md-flex">
              <p className="price-cotizacion">{priceQuote}</p>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <p className="footer-card-price mt-md-1">
              Rápido, Fácil y Seguro.
            </p>
            <p
              className="text-description-cotizacion"
              style={{ textAlign: "start" }}
            >
              *Oferta sujeta a inspección
            </p>
          </div>
          <div
            className="d-flex align-items-start justify-content-start flex-column d-md-none"
            style={{ width: "100%" }}
          >
            <p className="price-cotizacion">{priceQuote}</p>
            <p className="text-description-cotizacion">
              *Oferta sujeta a inspección
            </p>
          </div>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center mt-3">
          <p className="text-description-cotizacion mt-1 mb-2">
            En instantes recibirás la oferta por WhatsApp <br /> para continuar
            el proceso con un asesor.
          </p>
        </div>
        <div className="mt-2 mt-md-5 container-btn-success">
          <button className="purple-button-inspect me-2">
            <a
              href="https://turnos-auta-dot-admin-dev-355313.rj.r.appspot.com/turnos-auta/q9gtiYppNulewWob6KDR"
              target="_blank"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              Vender mi auto
            </a>
          </button>
          <button
            className="white-button-inspect me-md-2"
            onClick={handleClick}
          >
            Volver a cotizar
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessComponent;
