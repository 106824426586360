import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import BasePurpleButtonComponent from "../../../../components/BasePurpleButtonComponent";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { sendCotizacion } from "../../service/cotizar";
import { useSpinner } from "../../../../context/spinnerContext";
import SpinnerComponent from "../../../../components/SpinnerComponent";

export const ViewStepOneComponent = ({ selectedOptions, isGnc, kms }) => {
  let isPageWide = useMediaQuery("(min-width: 1000px)");
  const { phone } = useSelector((state) => state.userReducer);
  const { register, handleSubmit, reset, watch } = useForm();
  const [codArea, setcodArea] = useState(8);
  const [loadingPost, setLoadingPost] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showLoading, hideLoading, showSpinner } = useSpinner();

  // Watch the values of name and telefono
  const name = watch("name");
  const telefono = watch("telefono");

  // Enable the button when name and telefono are both filled
  useEffect(() => {
    setIsButtonEnabled(!!name && !!telefono);
  }, [name, telefono]);

  useEffect(() => {
    if (phone === null) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [phone]);

  const sendSale = (data) => {
    setLoadingPost(true);
    const telefonoCompleto = `549${data.cod_area}${data.telefono}`;
    sendCotizacion(
      selectedOptions?.brand?.name,
      selectedOptions?.brand?.id,
      selectedOptions?.model?.name,
      selectedOptions?.model?.id,
      selectedOptions?.version?.name,
      selectedOptions?.version?.id,
      selectedOptions?.year?.name,
      isGnc,
      kms,
      telefonoCompleto,
      data.name,
      data.email,
      navigate,
      dispatch,
      setLoadingPost
    );
    reset();
  };

  return (
    <>
      <div
        className={
          isPageWide ? "card-contact-data" : "mobile-dropdown-container"
        }
      >
        <div className="card-contact-header">
          <p className="contact-header-title">¡Completá tus datos!</p>
        </div>
        {showSpinner ? (
          <div style={{ marginTop: "50px" }}>
            <SpinnerComponent />
          </div>
        ) : (
          <>
            <div style={{ padding: "12px", height: "364px" }}>
              <form
                onSubmit={handleSubmit(sendSale)}
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div style={{ marginBottom: "12px" }}>
                    <label className="label-input">Nombre completo *</label>
                    <input
                      placeholder="Ingrese su nombre completo"
                      className="base-input"
                      {...register("name", {
                        required: true,
                      })}
                    />
                  </div>
                  <div style={{ marginBottom: "12px" }}>
                    <label className="label-input">Email</label>
                    <input
                      placeholder="Ingrese su email"
                      type="email"
                      className="base-input"
                      {...register("email")}
                    />
                  </div>
                  <div style={{ marginBottom: "12px" }}>
                    <label className="label-input">Teléfono celular *</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p className="phone-number">+549</p>
                      <input
                        type="text"
                        className="base-input"
                        placeholder="Ej: 11"
                        maxLength={3}
                        style={{ width: "65px", marginRight: "10px" }}
                        {...register("cod_area", {
                          required: true,
                        })}
                        onChange={(e) => {
                          setcodArea(e.target.value.length);
                        }}
                      />
                      <input
                        type="text"
                        className="base-input ms-1"
                        placeholder="Sin 15"
                        {...register("telefono", {
                          required: true,
                          maxLength: codArea ? Math.abs(codArea - 10) : 0,
                          minLength: codArea ? Math.abs(codArea - 10) : 0,
                        })}
                        maxLength={
                          codArea ? Math.abs(parseInt(codArea.length) - 10) : 0
                        }
                        minLength={
                          codArea ? Math.abs(parseInt(codArea.length) - 10) : 0
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-between align-items-center">
                  {loadingPost && (
                    <Spinner
                      animation="border"
                      style={{
                        margin: "10px",
                        color: " #7340d8",
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <BasePurpleButtonComponent
                    buttonText={loadingPost ? "Enviando..." : "Continuar"}
                    buttonDisabled={loadingPost || !isButtonEnabled}
                    type="submit"
                  />
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </>
  );
};
