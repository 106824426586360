import React, { useState } from "react";
import { Container, Navbar } from "react-bootstrap";
import LogoAutaHeader from "../images/logo-auta-header.png";
import "../styles/components/header.css";

export const HeaderComponent = () => {
  return (
    <>
      <Container
        style={{
          position: "sticky",
          top: "0",
          zIndex: "4",
        }}
      >
        <div className="container-all">
          <Navbar expand="md" className="header">
            <Navbar.Brand
              style={{ cursor: "pointer", padding: "0px !important" }}
            >
              <a href="https://auta.com.ar" target="_blank">
                <img
                  src={LogoAutaHeader}
                  alt="logo-auta"
                  className="logo-auta-header ms-3 ms-md-4"
                />
              </a>
            </Navbar.Brand>
          </Navbar>
        </div>
      </Container>
    </>
  );
};
