import React, { useEffect, useState } from "react";
import "./style/derivatorPages.css";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { HeaderComponent } from "../../components/HeaderComponent";
import ButtonGroupStepperSaleComponent from "./components/Mobile/ButtonGroupStepperSaleComponent";
import { CotizadorDesktopComponent } from "./components/Desktop/CotizadorDesktopComponent";
import { getYears, years } from "./service/fetchDataCar";

export default function CotizadorPage() {
  const [isTitleShow, setIsTitleShow] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [dataYears, setDataYears] = useState(years);
  let breakPointMobile = useMediaQuery("(min-width: 430px)");

  /* useEffect(() => {
    const link = "https://tesst-dot-admin-dev-355313.rj.r.appspot.com/";
    const safariLink = `x-web-search://?${link}`;
    window.location.href = safariLink;
  }, []); */

  useEffect(() => {
    getYears(setDataYears);
  }, []);

  return (
    <div style={{ height: breakPointMobile ? "94vh" : "95vh" }}>
      <HeaderComponent expanded={expanded} setExpanded={setExpanded} />
      <div className={`cotizador-desktop ${expanded ? "blur-background" : ""}`}>
        <CotizadorDesktopComponent dataYears={dataYears} />
      </div>
      <div className={`cotizador-mobile ${expanded ? "blur-background" : ""}`}>
        <div style={{ height: "100%" }}>
          <ButtonGroupStepperSaleComponent
            dataYears={dataYears}
            isCotizador={true}
            isTitleShow={isTitleShow}
            setIsTitleShow={setIsTitleShow}
          />
        </div>
      </div>
    </div>
  );
}
