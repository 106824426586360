import React from "react";
import BaseModalAuthComponent from "./BaseModalAuthComponent";
import { TitleHomeWithIcon } from "../../Home/components/TitleHomeWithIcon";
import { colors } from "../../../styles/colors";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { FormDataComponent } from "./FormDataComponent";

const ModalAuthComponent = ({
  isModalOpen,
  toggleLoginModal,
  getInWithEmail,
  setGetInWithEmail,
  showLogin,
  setShowLogin,
  selectedOptions,
  kms,
  isGnc,
}) => {
  let isPageWide = useMediaQuery("(min-width: 630px)");

  return (
    <BaseModalAuthComponent
      show={isModalOpen}
      onClose={toggleLoginModal}
      getInWithEmail={getInWithEmail}
      setGetInWithEmail={setGetInWithEmail}
      showLogin={showLogin}
      setShowLogin={setShowLogin}
    >
      <div className="mt-1 mb-2 mb-md-1">
        <TitleHomeWithIcon
          title={"Cotizá tu auto"}
          fontSize={isPageWide ? "30px" : "22px"}
          colorText={colors.purple.purple}
          colorIcon={colors.purple.purple}
        />
      </div>
      <FormDataComponent
        selectedOptions={selectedOptions}
        kms={kms}
        isGnc={isGnc}
      />
    </BaseModalAuthComponent>
  );
};

export default ModalAuthComponent;
