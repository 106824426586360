import React, { useState, useEffect } from "react";
import CardBaseAuthComponent from "./CardBaseAuthComponent";
import { useForm } from "react-hook-form";
import { sendCotizacion } from "../../Cotizador/service/cotizar";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import BasePurpleButtonComponent from "../../../components/BasePurpleButtonComponent";

export const FormDataComponent = ({ selectedOptions, isGnc, kms }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm();

  const [loadingPost, setLoadingPost] = useState(false);
  const [codArea, setcodArea] = useState(8);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Watch the values of name and telefono
  const name = watch("name");
  const telefono = watch("telefono");

  // Enable the button when name and telefono are both filled
  useEffect(() => {
    setIsButtonEnabled(!!name && !!telefono);
  }, [name, telefono]);

  const sendFormData = (data) => {
    const telefonoCompleto = `549${data.cod_area}${data.telefono}`;
    setcodArea(data.cod_area);
    setLoadingPost(true);
    sendCotizacion(
      selectedOptions?.brand?.name,
      selectedOptions?.brand?.id,
      selectedOptions?.model?.name,
      selectedOptions?.model?.id,
      selectedOptions?.version?.name,
      selectedOptions?.version?.id,
      selectedOptions?.year?.name,
      isGnc,
      kms,
      telefonoCompleto,
      data.name,
      data.email,
      navigate,
      dispatch,
      setLoadingPost
    );
    reset();
  };

  return (
    <CardBaseAuthComponent text={"¡Completá tus datos!"}>
      <form onSubmit={handleSubmit(sendFormData)}>
        <div className="d-flex align-items-center justify-content-center flex-column">
          <div
            className="d-flex align-items-start justify-content-start flex-column mt-3 mb-3"
            style={{ width: "100%" }}
          >
            <label className="label-input">Nombre completo *</label>
            <input
              placeholder="Ingrese su nombre completo"
              className="base-input"
              {...register("name", {
                required: true,
              })}
            />
          </div>
          <div
            className="d-flex align-items-start justify-content-start flex-column mb-3"
            style={{ width: "100%" }}
          >
            <label className="label-input">Email</label>
            <input
              placeholder="Ingrese su email"
              type="email"
              className="base-input"
              {...register("email")}
            />
          </div>
          <div className="mt-1 me-1" style={{ width: "97%" }}>
            <label className="label-input">Teléfono celular *</label>
            <div className="d-flex align-items-center">
              <p className="phone-number mb-2">+549</p>
              <div>
                <input
                  type="text"
                  className="base-input"
                  placeholder="Ej: 11"
                  maxLength={3}
                  style={{ width: "65px", marginRight: "0px" }}
                  {...register("cod_area", {
                    required: true,
                  })}
                  onChange={(e) => {
                    setcodArea(e.target.value.length);
                  }}
                />
              </div>
              <div>
                <input
                  type="text"
                  className="base-input ms-1"
                  placeholder="Sin 15"
                  {...register("telefono", {
                    required: true,
                    maxLength: Math.abs(codArea - 10),
                    minLength: Math.abs(codArea - 10),
                  })}
                  maxLength={Math.abs(parseInt(codArea?.length) - 10)}
                  minLength={Math.abs(parseInt(codArea?.length) - 10)}
                />
              </div>
            </div>
            <div className="mt-1">
              {errors.name && errors.name.type === "required" && (
                <p className="text-error">El nombre es requerido.</p>
              )}
              {errors.cod_area && errors.cod_area.type === "required" && (
                <p className="text-error">El código de area es requerido.</p>
              )}
              {errors.cod_area && errors.cod_area.type === "maxLength" && (
                <p className="text-error">
                  El código de area debe contener máximo 4 dígitos.
                </p>
              )}
              {errors.telefono && errors.telefono.type === "maxLength" && (
                <p className="text-error">
                  El número de teléfono debe ser de {Math.abs(codArea - 10)}{" "}
                  dígitos y sin 15.
                </p>
              )}
              {errors.telefono && errors.telefono.type === "minLength" && (
                <p className="text-error">
                  El número de teléfono debe ser de {Math.abs(codArea - 10)}{" "}
                  dígitos y sin 15.
                </p>
              )}
              {errors.telefono && errors.telefono.type === "required" && (
                <p className="text-error">
                  El número de teléfono es requerido.
                </p>
              )}
            </div>
          </div>
          <div className="d-flex flex-column justify-content-between align-items-center">
            {loadingPost ? (
              <Spinner
                animation="border"
                style={{ color: " #7340d8" }}
                className="mt-4"
              />
            ) : null}
          </div>
          <div className="d-flex align-items-center justify-content-center mt-4">
            <BasePurpleButtonComponent
              buttonText={loadingPost ? "Enviando..." : "Continuar"}
              buttonDisabled={loadingPost || !isButtonEnabled}
              type="submit"
            />
          </div>
        </div>
      </form>
    </CardBaseAuthComponent>
  );
};
